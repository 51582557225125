import AuboisNews from "../AuboisNews/index.js";
import Breadcrumb from "./Breadcrumb";
import Cities from "../Cities";
import Contact from "../Contact";
import { Context } from "../../utils/store";
import getPubs from "../../utils/pubs/getPubs";
import EphemeralChannel from "../EphemeralChannels/index.js";
import FirstStackArticles from "../Articles/FirstStackArticles";
import SeeMoreButton from "../SeeMoreButton";
import ShowsAndNews from "../Shows&News";
import StackTopic from "../Articles/StackTopic";
import { useContext,useState,useEffect } from "react";
import Modal from '@material-ui/core/Modal';
export default function ContentTemplate({
    children,
    type,
    data,
    onClickFunction,
}) {
    console.log(data)
    const context = useContext(Context);
    const [openPub,setOpenPub]=useState(true);
    const [pub,setPub]=useState(null);
    const [topPub,setTopPub] = useState(null);
    const [isVideo,setIsVideo] = useState(false);
    const { activePage } = context;
    const videoExt = ["video/webm","video/mp4","video/avi"];
    const [isMobile,setIsMobile]=useState(false)
    const getHomePub = async() =>{
        let pub = await getPubs('prehome',false,true,window.innerWidth<=1024);
        // const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi;
        if(pub?.image){
            fetch(pub.image, {
                mode: 'cors'
            }).then(function(response){
                return response.blob();
            }).then(function(file){
                if (videoExt.includes(file?.type))
                    setIsVideo(true);

                setPub(pub);
            })            
        }

    }
    let isEndReachedSeeMore = context?.isEndReachedSeeMore ?? false;
    const paddingContainer =
        type === "live" || type === "contact" ? "p-0" : "p-16";

    if (data && data?.items) data = data.items;

    const RightPart = () => {
        switch (type) {
            case "live": {
                return <ShowsAndNews />;
            }
            case "contact": {
                return <Contact />;
            }
            case "aroundme": {
                return <Cities handleCity={context.handleCity}/>;
            }
            default: {
                return (
                    <>
                        <ShowsAndNews />
                        <EphemeralChannel />
                        <AuboisNews />
                    </>
                );
            }
        }
    };

    const getTopPub = async() =>{
        let categoryID;
        let idRubrique = context.rubriqueData?.idRubrique;
        let activePage = context.activePage;
        let selectedPubs = [];
        let pubs = await getPubs('top',true,false);
        switch(activePage){
            case 'home' : 
                categoryID = 1;
                break;
            case 'aroundme' : 
                categoryID=2;
                break;

            case 'article': 
                idRubrique = data[0].parent;
                categoryID = context.thematiques.items.find(el => el.id_rubrique == idRubrique)?.id;
                break;

            default :
                categoryID = null;
                break;
        }

        if(categoryID){
            selectedPubs= selectedPubs.concat(pubs.filter(el=>el.categories.includes(categoryID)));    
        }


        setTopPub(selectedPubs)
    }

    useEffect(()=>{
        let isMount = true;
        setIsMobile(window.innerWidth<=1024);

        if(isMount){
            if(type=='home'){
                getHomePub();
            }
            getTopPub()
        }
        return ()=> isMount = false;
    },[data])


    return (
        <Context.Provider
            value={{
                ...context,
            }}
        >
            <Breadcrumb type={type} data={data}/>
            
            <div className="ct-container">
                <div className="ct-article-container">
                    {topPub !=null &&topPub.length>0 ?
                        <a href={topPub[0].lien} target="_blank" rel="noopener">
                            <img className="w-50per mx-auto mb-10" src={topPub[0].image} alt="Pub haut de page"/>
                        </a> 
                    :null}
                    <div
                        className={`${paddingContainer} bg-primary-white border-1 border-gray rounded`}
                    >
                        {children ? (
                            <div>{children}</div>
                        ) : type === "home" ? ( // If it's the Home page
                            data.map((e, i) => {
                                return (
                                    <FirstStackArticles
                                        key={i}
                                        stackID={i}
                                        data={e}
                                    />
                                );
                            })
                        ) : (
                            <StackTopic articles={data} type={type} />
                        )}
                    </div>
                    {type !== "article" &&
                        type !== "contact" &&
                        type !== "aroundme" &&
                        activePage !== "direct" &&
                        !isEndReachedSeeMore &&
                        data &&
                        data.length > 0 && (
                            <SeeMoreButton onClickFunction={onClickFunction} />
                        )}
                </div>
                <div className="ct-right-container">
                    <RightPart />
                </div>
            </div>

            {type == 'home' && pub!=null ? 
                <Modal
                    open={openPub}
                    onClose={()=>{}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="flex justify-center items-center"
                >
                    <div className="w-auto max-h-1/2 block h-full relative focus:outline-none">
                        <a href={pub.lien} target="_blank" rel="noopener">
                            {isVideo ? 
                                <video className="w-auto max-w-none h-full block mx-auto" autoPlay muted>
                                    <source src={isMobile ? pub.tablet : pub.video_webm ?pub.video_webm : pub.video_mp4 } />
                                </video>
                            :
                                <img src={isMobile ? pub.tablet : pub.image} className="w-auto max-w-none h-full block mx-auto" alt="Publicitié de l'accueil du site"/>
                            }
                            
                        </a>
                        <div className="app-button-primary w-auto absolute right-0 top-0 transform -translate-y-full" role="button" onClick={()=>setOpenPub(false)}>{'Accéder au site'}</div>
                    </div>
                    
                </Modal>
            : null}

        </Context.Provider>
    );
}
